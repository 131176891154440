<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "EditLeadActivity",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "2.7 How to edit Lead activity?",
          description: [
            {
              text: "1. Tap “Add activity”  to add interactions to the timeline. You can edit the activity by tapping on the activity row.",
              imgType: true,
              img: "85a.Contact info_edit.png",
            },
            {
              text: "2. You will see a bottom pop up that allows you to edit or delete the activity.",
              imgType: true,
              img: "85b.Contact_add activity-edit.png",
            },
            {
              text: "3. Once you’re done editing, tap the “Save activity” button to save the change on the contact. You can always edit or delete the activity at any time.",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
